@font-face {
  font-family: "Montserrat";
  src: url("./Components/Assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./Components/Assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("./Components/Assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("./Components/Assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("./Components/Assets/fonts/Montserrat-Light.ttf");
}
